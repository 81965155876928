import {
  lineDistance,
  lineString,
  Units,
  point as turfPoint,
  destination,
  round,
} from '@turf/turf';
import * as geofire from 'geofire-common';
import { GeoPoint } from '@freddy/common';

export abstract class GeoUtils {
  private static lengthCache = new Map<string, number>();

  static calculateLength(geoPoints: GeoPoint[]): number {
    const key = geoPoints.map((p) => `${p.lat},${p.lon}`).join('-');
    if (this.lengthCache.has(key)) {
      return this.lengthCache.get(key) as number;
    }

    const line = lineString(geoPoints.map((g) => [g.lon, g.lat]));
    const length = round(lineDistance(line, { units: 'meters' }), 2);

    this.lengthCache.set(key, length);
    return length;
  }

  static getShortestPaths(
    nbOfPaths: number,
    points: GeoPoint[],
    variationFactor = 0.3,
  ): GeoPoint[][] {
    if (!points || points.length < 2 || nbOfPaths < 1) {
      return [];
    }

    const paths: GeoPoint[][] = [];
    const pathLengths: number[] = [];
    const usedStartIndices = new Set<number>();

    for (let i = 0; i < nbOfPaths; i++) {
      let startIndex: number;

      if (usedStartIndices.size < points.length) {
        // Find an unused start index
        do {
          startIndex = Math.floor(Math.random() * points.length);
        } while (usedStartIndices.has(startIndex));
        usedStartIndices.add(startIndex);
      } else {
        // If all indices have been used, reset and start over
        startIndex = i % points.length;
        if (startIndex === 0) usedStartIndices.clear();
      }

      const path = this.getVariationalShortestPath(
        points,
        startIndex,
        variationFactor,
      );
      const length = this.calculateLength(path);

      // Use insertion sort to maintain a sorted list of paths
      let j = paths.length;
      while (j > 0 && pathLengths[j - 1] > length) {
        paths[j] = paths[j - 1];
        pathLengths[j] = pathLengths[j - 1];
        j--;
      }
      paths[j] = path;
      pathLengths[j] = length;

      // Keep only the top 'nbOfPaths'
      if (paths.length > nbOfPaths) {
        paths.pop();
        pathLengths.pop();
      }
    }

    return paths;
  }

  private static getVariationalShortestPath(
    points: GeoPoint[],
    startIndex: number,
    variationFactor: number,
  ): GeoPoint[] {
    const unvisited = new Set(points);
    let currentPoint = points[startIndex];
    unvisited.delete(currentPoint);

    const path: GeoPoint[] = [currentPoint];

    while (unvisited.size > 0) {
      const nextPoint = this.findNextPointWithVariation(
        currentPoint,
        unvisited,
        variationFactor,
      );
      unvisited.delete(nextPoint);
      path.push(nextPoint);
      currentPoint = nextPoint;
    }

    return path;
  }

  private static findNextPointWithVariation(
    currentPoint: GeoPoint,
    pointsSet: Set<GeoPoint>,
    variationFactor: number,
  ): GeoPoint {
    const candidates = Array.from(pointsSet);
    candidates.sort(
      (a, b) =>
        this.calculateLength([currentPoint, a]) -
        this.calculateLength([currentPoint, b]),
    );

    // Introduce variation: select not always the closest, but one of the top closest
    const variationIndex = Math.min(
      candidates.length - 1,
      Math.floor(Math.random() * variationFactor * candidates.length),
    );
    return candidates[variationIndex];
  }

  static getDestination(
    geoPoint: GeoPoint,
    distanceInMeters: number,
    angle: number,
  ): GeoPoint {
    const point = turfPoint([geoPoint.lon, geoPoint.lat]);
    const options = { units: 'meters' as Units };
    const dest = destination(point, distanceInMeters, angle, options);
    return {
      lon: dest.geometry.coordinates[0],
      lat: dest.geometry.coordinates[1],
    };
  }

  static getGeoHashForLocation(point: GeoPoint) {
    return geofire.geohashForLocation([point.lon, point.lat]);
  }
}
