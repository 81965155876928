import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  getImageProvider,
  NgxDragResizeModule,
  SharedCommonModule,
} from '@freddy/common';
import { MarkersMapInputComponent } from './components/inputs/markers-map-input/markers-map-input.component';
import { RadioInputComponent } from './components/inputs/radio-input/radio-input.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { DropboxInputComponent } from './components/inputs/dropbox-input/dropbox-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { TabLabelComponent } from './components/tab-label/tab-label.component';
import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { MinMaxChallengeInputComponent } from './components/inputs/challenges/min-max-challenge-input/min-max-challenge-input.component';
import { GuidedChallengeInputComponent } from './components/inputs/challenges/guided-challenge-input/guided-challenge-input.component';
import { ArChallengeInputComponent } from './components/inputs/challenges/ar-challenge-input/ar-challenge-input.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { NgxDropzoneModule } from './dropzone/ngx-dropzone.module';
import { MapComponent } from './components/map/map.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ComboboxInputComponent } from './components/inputs/combobox-input/combobox-input.component';
import { CollectionListComponent } from './components/collection-list/collection-list.component';
import { DateValueAccessorModule } from 'angular-date-value-accessor';
import { NgxIntlTelInputModule } from '@justin-s/ngx-intl-tel-input';
import { CollectionListItemComponent } from './components/collection-list/collection-list-item/collection-list-item.component';
import { MultiChoicesInputComponent } from './components/inputs/challenges/multi-select-input/multi-choices-input.component';
import { HotToastModule } from '@ngneat/hot-toast';
import { CopyDirective } from './directives/copy.diretive';

import { HasRoleDirective } from './directives/has-role.directive';
import { TeamMapMarkerComponent } from './components/team-map-marker/team-map-marker.component';
import { environment } from '../../environments/environment';
import { ComponentAnchorDirective } from './directives/component-anchor.directive';
import { MarkerHostComponent } from './components/marker-host/marker-host.component';
import { DynamicIoModule } from 'ng-dynamic-component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { SlideOverComponent } from './components/slide-over/slide-over.component';
import { ImageFallbackDirective } from '../../../../freddy/src/app/shared/directives/image-fallback.directive';

@NgModule({
  declarations: [
    MarkersMapInputComponent,
    RadioInputComponent,
    CopyDirective,
    DropboxInputComponent,
    TabsComponent,
    TabItemComponent,
    TabLabelComponent,
    TabBodyComponent,
    MinMaxChallengeInputComponent,
    GuidedChallengeInputComponent,
    ArChallengeInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    MapComponent,
    ComboboxInputComponent,
    CollectionListComponent,
    CollectionListItemComponent,
    MultiChoicesInputComponent,
    HasRoleDirective,
    TeamMapMarkerComponent,
    ComponentAnchorDirective,
    MarkerHostComponent,
    ImageFallbackDirective,
  ],
  imports: [
    CommonModule,
    SharedCommonModule,
    NgOptimizedImage,
    NgxMapboxGLModule,
    NgxDropzoneModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    DateValueAccessorModule,
    NgxIntlTelInputModule,
    HotToastModule.forRoot(),
    DynamicIoModule,
    NgxDragResizeModule,
    RouterOutlet,
  ],
  providers: [getImageProvider(environment.firebase.storageBucket)],
  exports: [
    CommonModule,
    SharedCommonModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    DateValueAccessorModule,
    MarkersMapInputComponent,
    NgxMapboxGLModule,
    RadioInputComponent,
    DropboxInputComponent,
    TabsComponent,
    TabLabelComponent,
    TabBodyComponent,
    TabItemComponent,
    MinMaxChallengeInputComponent,
    GuidedChallengeInputComponent,
    ArChallengeInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    MapComponent,
    AngularSvgIconModule,
    ComboboxInputComponent,
    CollectionListComponent,
    MultiChoicesInputComponent,
    CopyDirective,
    HasRoleDirective,
    DynamicIoModule,
    ImageFallbackDirective,
  ],
})
export class SharedModule {}
