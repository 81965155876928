import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { createTracingProviders } from '@freddy/common';
const appVersion = require('../../../../package.json').version;

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, AppRoutingModule, SharedModule],
  bootstrap: [AppComponent],
  providers: [...createTracingProviders(environment, appVersion)],
  exports: [CoreModule],
})
export class AppModule {}
