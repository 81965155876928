import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetUserDataAction } from '../actions/authentication.action';
import { Navigate } from '@ngxs/router-plugin';
import { TenantService } from './tenant.service';
import { take } from 'rxjs/operators';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  getIdTokenResult,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  User,
} from '@angular/fire/auth';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { HotToastService } from '@ngneat/hot-toast';
import { User as FreddysUser } from '@freddy/models';
import { getAuth } from 'firebase/auth';
import { environment } from '../../../../environments/environment';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private tenantService: TenantService,
    private toastService: HotToastService,
    private store: Store,
  ) {
    authState(this.auth).subscribe(async (user) => {
      this.getOrCreateUserFromFirebase(user);
    });
  }

  getOrCreateUserFromFirebase(user: User | null) {
    if (user) {
      this.createUserDocIfNotExisting(user).then(() => {
        const userDocRef = doc(
          this.firestore,
          `${this.tenantService.getOrganizationPrefixPath()}users/${user.uid}`,
        );
        docData(userDocRef, { idField: 'uid' })
          .pipe(take(1))
          .subscribe((userData: any) => {
            this.store.dispatch(new SetUserDataAction(userData));
          });
      });
    } else {
      this.store.dispatch(new SetUserDataAction(null));
    }
  }

  async sendVerificationMail() {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      try {
        await sendEmailVerification(currentUser);
        this.toastService.success('Verification email sent.');
      } catch (error) {
        console.error(error);
      }
    }
  }

  async createUserWithEmailAndPassword(email: string, password: string) {
    const userCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password,
    );
    const user = userCredential.user;
    if (user) {
      await this.setUserData(user);
      await this.sendVerificationMail();
      this.store.dispatch(
        new Navigate([
          this.tenantService.currentOrganizationSlug,
          'auth',
          'verify-email',
        ]),
      );
    }
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      this.store.dispatch(new Navigate(['/dashboard']));
    } catch (error) {
      this.toastService.error('Invalid email or password.');
    }
  }

  async signOut() {
    await this.auth.signOut();
    this.store.dispatch(
      new Navigate([
        this.tenantService.currentOrganizationSlug,
        'auth',
        'sign-in',
      ]),
    );
  }

  signUnWithCustomToken(token: string, tenantId?: string | null) {
    if (tenantId) {
      getAuth().tenantId = tenantId ?? null;
    }
    return fromPromise(signInWithCustomToken(this.auth, token));
  }

  async signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      environment.name === 'local'
        ? await signInWithPopup(this.auth, provider)
        : await signInWithRedirect(this.auth, provider);
    } catch (error) {
      console.error(error);
    }
  }

  async forgotPassword(email: string) {
    try {
      await sendPasswordResetEmail(this.auth, email);
      this.toastService.info('Password reset email sent, check your inbox.');
    } catch (error) {
      console.error(error);
    }
  }

  logout(): Promise<void> {
    return this.auth.signOut();
  }

  private async createUserDocIfNotExisting(user: User) {
    if (user) {
      await this.setUserData(user);
    }
  }

  private async setUserData(user: User) {
    const token = await getIdTokenResult(user);
    if (
      token.claims.organization &&
      typeof token.claims.organization === 'string'
    ) {
      this.tenantService.currentOrganizationSlug = token.claims.organization;
    }
    const userRef = doc(
      this.firestore,
      `${this.tenantService.getOrganizationPrefixPath()}users/${user.uid}`,
    );
    // @ts-ignore
    const userData: FreddysUser = {
      admin: !!token.claims.admin,
      evaluator: !!token.claims.evaluator,
      partner: !!token.claims.partner,
      manager: !!token.claims.manager,
      member: !!token.claims.member,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      createdAt: new Date(),
      ...(token.claims.organization
        ? { organization: token.claims.organization }
        : {}),
    };
    await setDoc(userRef, userData, { merge: true });
  }
}
