import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, switchMap, take, tap, catchError } from 'rxjs/operators';
import { combineLatest, from, Observable, of, timer } from 'rxjs';
import { TenantService } from '../services/tenant.service';
import { Auth, authState, User } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import { AppLoaderAction } from '../../layout/actions/layout.actions';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication.store';

@Injectable({ providedIn: 'root' })
export class DefaultGuard {
  constructor(
    private auth: Auth,
    private router: Router,
    private tenantService: TenantService,
    private authService: AuthService,
    private store: Store,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParamMap.get('token');
    const tenantId = route.queryParamMap.get('tenantId');
    const organization = route.queryParamMap.get('organization');

    this.store.dispatch(new AppLoaderAction(true));

    if (organization) {
      this.tenantService.currentOrganizationSlug = organization;
    }

    return token
      ? this.signInWithToken(token, tenantId)
      : this.checkAuthState();
  }

  private signInWithToken(
    token: string,
    tenantId?: string | null,
  ): Observable<boolean> {
    return this.authService.signUnWithCustomToken(token, tenantId).pipe(
      switchMap(() => this.checkAuthState()),
      catchError((error) => {
        console.error('Error signing in with token:', error);
        this.navigateToSignIn();
        return of(false);
      }),
    );
  }

  private checkAuthState(): Observable<boolean> {
    return timer(3000).pipe(
      switchMap(() =>
        combineLatest([
          authState(this.auth),
          this.store.select(AuthenticationState.user),
        ]),
      ),
      take(1),
      switchMap(([firebaseAuthState]) =>
        this.handleAuthState(firebaseAuthState),
      ),
      tap(() => this.store.dispatch(new AppLoaderAction(false))),
      map(Boolean),
      catchError((error) => {
        console.error('Error checking auth state:', error);
        this.navigateToSignIn();
        return of(false);
      }),
    );
  }

  private handleAuthState(firebaseAuthState: User | null): Observable<boolean> {
    if (!firebaseAuthState) {
      this.navigateToSignIn();
      return of(false);
    }

    return from(firebaseAuthState.getIdTokenResult(true)).pipe(
      map((idTokenResult) => {
        const isCustomAuth =
          idTokenResult.claims['firebase']?.['sign_in_provider'] === 'custom';
        const hasRequiredClaims = this.hasRequiredClaims(idTokenResult.claims);

        this.store.dispatch(new AppLoaderAction(false)); // TODO: Check to remove
        if (!hasRequiredClaims) {
          this.navigateToNotAuthorized();
          return false;
        }

        if (!isCustomAuth && !firebaseAuthState.emailVerified) {
          this.navigateToVerifyEmail();
          return false;
        }

        return true;
      }),
    );
  }

  private hasRequiredClaims(claims: { [key: string]: any }): boolean {
    return (
      claims.admin ||
      claims.partner ||
      claims.manager ||
      claims.member ||
      claims.evaluator
    );
  }

  private navigateToSignIn(): void {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      'sign-in',
    ]);
  }

  private navigateToNotAuthorized(): void {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      'not-authorized',
    ]);
  }

  private navigateToVerifyEmail(): void {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      'verify-email',
    ]);
  }
}
