export const environment = {
  name: 'staging',
  version: 'v1-232-0',
  production: true,
  sentry: {
    dsn: 'https://8e8579440b241f7beca527816cf959a3@o4506207716114432.ingest.sentry.io/4506581323153408',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    showDialog: true,
  },
  firebase: {
    region: 'europe-west1',
    projectId: 'freddy-staging-f98b6',
    appId: '1:421881520656:web:e142a89580f3e778426095',
    storageBucket: 'freddy-staging-f98b6.appspot.com',
    apiKey: 'AIzaSyCjCK5q3hrftECRc7eET4-HWXxN7Ipu4Gw',
    authDomain: 'staging-admin.freddy.ovh',
    messagingSenderId: '421881520656',
    measurementId: 'G-399HDLGTDJ',
    recaptcha: '6LffzlcpAAAAAKHDLbIvIhEEhD9hc8v6VNBwDMBf',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNsZTRvdzlwajA1N28zb3BiaTB5aXB2aTAifQ.92uATyIv0TO1Xx93i14xhg',
    geocoder: {
      countriesActivated: 'fr,nl,be,de',
    },
  },
  typesense: {
    apiKey: 'freddy-admin',
    host: 'search.freddy.ovh',
    port: 443,
    protocol: 'https',
    scenariosCollectionName: 'scenarios-staging',
    gamesCollectionName: 'games-staging',
  },
  game: {
    defaultRadius: 15,
    defaultPoints: 10,
  },
};
